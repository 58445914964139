@import url("https://fonts.googleapis.com/css2?family=Cairo&family=Roboto&display=swap");

html {
  font-family: "Cairo", sans-serif;
}

.filepond--item {
  /* width: calc(33.33% - 0.5em); */
  height: 100px;
  min-height: 80px;
  margin: 0.25em;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: #fff;
}
.multiFilePond .filepond--item {
  width: calc(33.33% - 0.5em);
}

.MuiDataGrid-columnHeader span {
  display: none;
}

.inputLTR .muirtl-n7x3nf-MuiFormLabel-root-MuiInputLabel-root {
  left: 25px;
  transform-origin: top left;
}

.inputLTR .muirtl-no4hy4-MuiFormLabel-root-MuiInputLabel-root {
  left: 27px;
  transform-origin: top left;
}

.inputLTR .muirtl-1d3z3hw-MuiOutlinedInput-notchedOutline {
  text-align: left;
}

.e-block-appointment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.e-block-appointment .e-subject {
  font-size: 16px;
  text-align: center;
}
.schedule {
  display: flex;
  flex-direction: column;
}

.header-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.dentist-name-header {
  width: 100px;
}

/* .hour-header {
  width: 100px;
  text-align: center;
  font-weight: bold;
} */

.dentist-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.dentist-name {
  width: 100px;
  font-weight: bold;
}

.dentist-schedule {
  display: flex;
  flex-grow: 1;
}

.cell {
  width: 100px;
  height: 50px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cell.rest-time {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.appointment {
  /* background-color: #bbdc00;
  color: #000;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  text-align: center; */
  cursor: default;
}

.rest-time-label {
  color: #888;
  font-size: 12px;
}
